<template>
  <div>
    <eden-page-header :title="'Services'" :subtitle="`${formatText(service)}`">
      <template slot="actions">
        <div class="is-flex is-flex-wrap align-center">
          <el-button
            type="plain"
            @click="goToLogs"
            v-if="
              service === 'meal' &&
              (!$route.query.tab || $route.query.tab === 'menu')
            "
          >
            View Audit Log
          </el-button>
        </div>
      </template>
    </eden-page-header>
    <component :is="component" />
  </div>
</template>

<script>
import * as mutations from "@/store/mutation-types";

export default {
  name: "Service",
  components: {
    Beauty: () => import("@/components/Services/Beauty/Beauty"),
    Cleaning: () => import("@/components/Services/Cleaning/Cleaning"),
    Laundry: () => import("@/components/Services/Laundry/Laundry"),
    Meals: () => import("@/components/Services/Meal/Meal"),
  },
  data() {
    return {};
  },
  computed: {
    service() {
      return this.$route.params.service;
    },
    component() {
      return this.service === "meal" ? "meals" : this.service;
    },
  },
  beforeRouteLeave(to, from, next) {
    next();
    this.$store.commit(mutations.SERVICE, {
      clear: true,
    });
  },
  methods: {
    goToLogs() {
      this.$router.push({
        name: "services.service-type.logs",
        params: {
          service: "meal",
        },
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
